import { SectionHeading, Subheading } from "components/misc/Headings";

import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const MarginTop = tw.div`mt-0 md:pt-24`;
const LeftColumn = tw.section`md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1`;
const Pill = tw.span`font-serif tracking-widest px-5 mt-16 py-1 rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const TwoColumn = tw.section`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center `;
const SubTitle = tw(Subheading)`text-lg tracking-widest`;

const CourseObjective = () => {
  return (
    <TwoColumn>
      <LeftColumn>
        <SectionHeading>Course Objectives</SectionHeading>
        <SubTitle>
          <Subheading>A1 Level</Subheading>
        </SubTitle>
        <SectionDescription>
          Acquire basic vocabulary and grammar to communicate in simple everyday
          situations. Learn to introduce yourself, ask and answer basic
          questions, and understand simple texts.
        </SectionDescription>
        <SubTitle>
          <Subheading>A2 Level</Subheading>
        </SubTitle>
        <SectionDescription>
          Expand your ability to discuss familiar topics, describe your
          background, and handle routine tasks. Develop listening and reading
          comprehension of longer texts.
        </SectionDescription>
      </LeftColumn>
      <RightColumn>
        <MarginTop />
        <SubTitle>
          <Subheading>B1 Level</Subheading>
        </SubTitle>
        <SectionDescription>
          Gain confidence in more complex conversations, express opinions, and
          discuss personal interests and experiences. Improve your ability to
          understand and produce detailed written and spoken German.
        </SectionDescription>
        <SubTitle>
          <Subheading>B2 Level</Subheading>
        </SubTitle>
        <SectionDescription>
          Achieve greater fluency and accuracy, enabling you to participate in
          discussions on a wide range of topics. Enhance your ability to
          understand and analyze more complex texts and articulate your thoughts
          clearly in both written and spoken forms.
        </SectionDescription>
      </RightColumn>
    </TwoColumn>
  );
};

export default CourseObjective;
