import {
  SectionHeading,
  Subheading,
} from "components/misc/Headings.js";

import React from "react";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.section`relative my-10 max-w-screen-xl mx-auto`;
const CenterContainer = tw.section` `;
//const Link = tw.a``;
const Center = tw.div`mt-10 flex justify-center items-center`;

const Wrapper = styled.section`
  ${tw`flex flex-col md:flex-row items-center  flex-wrap justify-evenly`}
`;
const Heading = tw(
  Subheading
)`font-serif  text-sm bg-secondary-500 text-white md:text-2xl rounded-full px-5 py-2`;

const DecoratorBlob = tw(
  SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-secondary-500 -z-10`;

const Column = styled.div`
  ${tw`rounded-t border-b px-20   py-5    flex flex-col items-center flex border-gray-100 `}
`;

const Card = styled.a`
  ${tw`w-[90%] md:w-[40%] lg:w-[24%] mb-5  border-b cursor-pointer border-gray-100 shadow-special rounded pb-10  transition duration-150`}
  .title {
    ${tw`font-serif bg-primary-100 px-5 py-2 rounded-full text-center mt-5   uppercase text-sm md:text-lg leading-none text-primary-500`}
  }
  .priceContainer {
    ${tw`text-center mt-5 font-normal  md:font-light text-xl md:text-3xl tracking-wider`}
  }

  .currency {
    ${tw`text-primary-500`}
  }
  .linkTo {
    ${tw`text-center font-light text-sm md:text-lg border border-secondary-500  rounded-full px-10 py-2 font-serif text-secondary-500 hover:text-gray-100 hover:bg-secondary-500 text-center tracking-widest`}
  }
`;

const CourseFee = ({ data }) => {
  return (
    <Container>
      <CenterContainer>
        <Center><SectionHeading>COURSE FEE</SectionHeading>  </Center>  
        <Wrapper>
          {data?.map((card, i) => (
            <Card key={card.id}>
              <Column>
                <Heading>{card?.level}</Heading>
              </Column>
              <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
              <p className="title">{card?.module}</p>
              <p className="priceContainer">
                <span>{card?.price}</span>
                <span className="currency">{card?.currency}</span>
              </p>
              </div>

              <Center>
              <b className="duration">{card?.duration}</b>
              </Center>

              <Center>
              <b> + Ausbildung Workshop, CV etc. </b> 
              </Center>
              <Center>
              <b>+ Access to our Employer Network</b> 
              </Center>
             { /*<Center>
                <Link className="linkTo" href="#register">
                  Enroll
                </Link>
              </Center>*/}
            </Card>
          ))}
          <DecoratorBlob />
        </Wrapper>
      </CenterContainer>
    </Container>
  );
};

export default CourseFee;
