import { Link } from "react-router-dom";
import React from "react";
import { SectionHeading } from "components/misc/Headings.js";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.section`relative max-w-screen-xl mx-auto`;

const ThreeColumnContainer = styled.div`
  ${tw`mb-5 flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto`}
`;
const Heading = tw(SectionHeading)`font-serif text-center mt-10 -mb-5`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 flex`}
`;

const Card = styled.div`
  ${tw`z-20 px-5 cursor-pointer flex flex-col mx-auto max-w-xs items-center py-10 border border-gray-100 shadow-raised rounded-lg mt-8`}
  .imageContainer {
    ${tw`text-center rounded-full px-6 flex-shrink relative`}
    img {
      ${tw`h-20`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-serif px-2 capitalize font-medium text-sm md:text-lg leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 mb-2 px-2 md:px-5 border border-secondary-500 rounded-full  font-medium font-serif text-secondary-500 text-sm md:text-lg text-center leading-loose`}
  }
  .desc {
    ${tw`mt-3 font-light text-gray-500 text-sm text-justify leading-loose`}
  }
  .button {
   ${tw`mt-3 transform rounded-full bg-secondary-500 font-serif text-white rounded px-6 font-thin tracking-widest py-1 self-auto mx-auto my-auto`}
  }
   .linkTo{
   ${tw`mt-5 transform font-light text-sm bg-secondary-500 hover:bg-secondary-600 rounded-full px-10 py-2 font-serif text-white hover:text-gray-100 text-sm text-center tracking-widest`}
   }
`;

const CoursesSection = ({ data, heading }) => {
  return (
    <Container>
      <Heading>{heading}</Heading>
      <ThreeColumnContainer>
        {data?.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imgSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.name}</span>
                <p className="description">
                  {card.levels} | {card?.duration}
                </p>
              </span>
              <p className="desc">{card?.desc}</p>
              <Link className="linkTo" to={card?.linkTo}>
                Learn More
              </Link>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};

export default CoursesSection;
