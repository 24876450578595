import { SectionHeading, Subheading } from "components/misc/Headings";

import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1 md:pt-16`;
//const Pill = tw.span`font-serif md:tracking-widest px-2 md:px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const TwoColumn = tw.section`md:mt-5 flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center `;
const SubTitle = tw(Subheading)` tracking-widest`;

const CourseObjectivePage = () => {
  return (
    <TwoColumn>
      <LeftColumn>
        <SectionHeading>What We Offer</SectionHeading>
        <SubTitle>
          <Subheading>Job Market and Career Guidance</Subheading>
        </SubTitle>
        <SectionDescription>
        Get in-depth knowledge of the German job market, including where to find 
        job opportunities, how to write a standout German-style CV, 
        and how to prepare for job interviews. We’ll help you understand German workplace culture and expectations, 
        enabling you to approach your career in Germany with confidence.
        </SectionDescription>
        <SubTitle>
          <Subheading>Relocation Assistance</Subheading>
        </SubTitle>
        <SectionDescription>
        Moving to a new country involves a lot of paperwork and administrative tasks. 
        Our coaching includes guidance on finding housing, registering your address, 
        opening bank accounts, obtaining health insurance, and understanding Germany’s
         social security system. We simplify these complex processes, 
        so you can focus on settling into your new environment.
        </SectionDescription>
      </LeftColumn>
      <RightColumn>
        <SubTitle>
          <Subheading>Cultural Integration</Subheading>
        </SubTitle>
        <SectionDescription>
        Adapting to a new culture is key to feeling at home in Germany. 
        We provide insights into German customs, etiquette, social norms, and daily life. 
        Learn how to interact in both professional and social settings, 
        understand the local mindset, and navigate cultural differences with ease.
        </SectionDescription>
        <SubTitle>
          <Subheading> Personalized Coaching</Subheading>
        </SubTitle>
        <SectionDescription>
        Every journey is unique, so we tailor our coaching sessions to your individual needs, 
        goals, and concerns. Whether you need help with job applications, navigating German bureaucracy, 
        or understanding cultural nuances, 
        your coach will provide personalized advice and support to ensure your success.
        </SectionDescription>
      </RightColumn>
    </TwoColumn>
  );
};

export default CourseObjectivePage;
