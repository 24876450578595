import AnimationRevealPage from "helpers/AnimationRevealPage";
import BenefitsPage from "./Benefits";
//import PositionPage from "./Positions";
//import { CourseCustomerCount } from "data/dummyData";
import CourseFee from "./CourseFee";
import CourseObjectivePage from "./CourseObjectivePage";
//import CustomerCountPage from "components/customer-count/CustomerCount";
//import EntryRequirementPage from "./EntryRequirement";
import FooterPage from "components/Footer";
import GermanLanguagePage from "pages/home/GermanLanguage";
//import KeyTopicsPage from "./KeyTopics";
import LangIMG from "images/ausbildung-prog.svg";
import OverviewSection from "./OverviewSection";
//import ProfessionalSchedulePage from "./ProfessionalSchedule";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { AusbildungFeeData } from "data/dummyData";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";
import SEO from "components/seo/SEO";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw.section` mt-32  relative`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

//meta title
document.title =
  "Apprenticeship Program - Weg2De Akademie | Ausbildung in Germany | Ausbildung preparatory Course | German Course for Ausbildung";

function ApprenticeshipProgramPage() {
  return (
    <AnimationRevealPage>
        <SEO
        title="Weg2De Apprenticeship (Ausbildung) Program - WAPP"
        description="Weg2De - Find a Job in Germany - Find Ausbildung in Germany - Learn German Online - Study in Germany - If you're considering moving to Germany or another country in the DACH region for work, study, or vocational training, you're in the right place "
        name="Weg2De Apprenticeship (Ausbildung) Program - WAPP"
        type=""
      />
      <Container>
        <OverviewSection heading="Apprenticeship Program" highlight=" (Ausbildung)" />
        <CourseObjectivePage />
        <BenefitsPage />
        <CourseFee data={AusbildungFeeData} />
        <GermanLanguagePage
          subheading="Ausbildung Program"
          heading="Interested in our Program?"
          description="Are you interested in enrolling for The Weg2De Apprenticeship Preparatory Program (WAPP)? Click the Link below to register"
          imageSrc={LangIMG}
          showDecoratorBlob={true}
          textOnLeft={false}
          primaryButtonUrl="/contact-us"
        />
        <FooterPage />
        <DecoratorBlob1 />
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
}

export default ApprenticeshipProgramPage;
