import { WhatWeDoData, homepageFaqs } from "data/dummyData";

import AboutPage from "./About";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import CompanyServicesPage from "pages/candidates/ServicePortfolio";
import FAQIMG from "images/ishaq.webp";
import FaqPage from "components/FAQ";
import FooterPage from "components/Footer";
import GermanLanguagePage from "./GermanLanguage";
import LandingPage from "./LandingPage";
import LearnGerman from "images/learn-german.svg";
import React from "react";
import SEO from "components/seo/SEO";

// title, description, name, type

const HomePage = () => {
  return (
    <AnimationRevealPage>
      <SEO
        title="Home - Weg2De | Your pathway to Education & Career opportunities in Germany & DACH region"
        description="Study, Work & Live in Germany - Ausbildung in Germany, Work in Germany, Learn German Online"
        name="Weg2De"
        type=""
      />
      <LandingPage />
      <AboutPage />
      <CompanyServicesPage heading="What We Do" data={WhatWeDoData} />
      <GermanLanguagePage
        subheading="German Language"
        heading="Learn German with us Today"
        imageSrc={LearnGerman}
        showDecoratorBlob={true}
        textOnLeft={false}
      />
      <FaqPage
        subheading="FAQS"
        heading="Do you have Questions?"
        imageSrc={FAQIMG}
        faqs={homepageFaqs}
      />
      <FooterPage />
    </AnimationRevealPage>
  );
};

export default HomePage;
