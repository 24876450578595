import './HiringSteps.css';

import React from "react";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.section`relative mt-20 max-w-screen-xl mx-auto`;
const Wrapper = styled.section`
  ${tw`mb-20 flex flex-col flex-wrap md:flex-row justify-between`}
`;

const DecoratorBlob = tw(
  SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform translate-x-2/3 translate-y-2/3 fill-current text-secondary-500 -z-20`;

const Card = styled.section`
  ${tw` flex flex-col items-center md:w-[18%]`}
  .title {
    ${tw`font-serif text-center pt-3  mb-2  font-light  font-normal text-sm leading-tight  text-primary-500`}
  }
  .cardBody {
    ${tw`h-[100%] shadow-special px-1 pb-5 flex flex-col items-center rounded transition duration-150`}
  }
  .desc {
    ${tw`pt-1 text-justify font-light px-5  text-sm text-gray-500`}
  }
    .hiringImg {
      ${tw`w-10 h-10 mt-2`}
    }
`;
const SectionTitle = tw(SectionHeading)`font-serif text-center mt-10 mb-3`;

const HiringSteps = ({ data, heading }) => {
  return (
    <Container>
      <SectionTitle>{heading}</SectionTitle>
        <Wrapper>
          {data?.map((card) => (
            <Card key={card?.id}>
              <span className="cardTop">{card?.id}</span>
              <div className="cardBody">
                <p className="title">{card?.title}</p>
                <p className="desc">{card?.desc}</p>
              </div>
            </Card>
          ))}
          <DecoratorBlob />
        </Wrapper>
    </Container>
  );
};

export default HiringSteps;
