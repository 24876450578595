import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { NavLink as NAV } from "react-router-dom";
import React from "react";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import logo from "../../images/companyLogo.png";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

const Header = tw.header`px-5 font-serif
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-500 will apply the bg-primary-500 class on hover or focus
 */
export const NavLink = tw.span`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-normal tracking-wide transition duration-300 text-gray-500
  pb-1 border-b-2 border-transparent hover:border-gray-500 hocus:text-gray-400
`;

export const PrimaryLink = tw.a`
  lg:mx-0
  px-8 py-3 rounded bg-secondary-500 text-white
  hocus:bg-secondary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
  rounded-full
  cursor-pointer
`;

export const LogoLink = styled(NAV)`
  ${tw`flex items-center`};
  img {
    ${tw``}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-secondary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden border border-gray-100  h-[100vh] z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8  text-center rounded-lg text-gray-500 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();

  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink>
        <NAV
          style={({ isActive }) => {
            return {
              fontWeight: isActive ? "600" : "",
              color: isActive ? "#ec1c24" : "",
            };
          }}
          to="/"
          onClick={toggleNavbar}
        >
          Home
        </NAV>
      </NavLink>
      <NavLink>
        <NAV
          style={({ isActive }) => {
            return {
              fontWeight: isActive ? "600" : "",
              color: isActive ? "#ec1c24" : "",
            };
          }}
          to="/arbeitgeber"
          onClick={toggleNavbar}
        >
          Für Arbeitgeber
        </NAV>
      </NavLink>
      <NavLink>
        <NAV
          style={({ isActive }) => {
            return {
              fontWeight: isActive ? "600" : "",
              color: isActive ? "#ec1c24" : "",
            };
          }}
          to="/candidates"
          onClick={toggleNavbar}
        >
          Candidates
        </NAV>
      </NavLink>
      <NavLink>
        <NAV
          style={({ isActive }) => {
            return {
              fontWeight: isActive ? "600" : "",
              color: isActive ? "#ec1c24" : "",
            };
          }}
          to="/akademie"
          onClick={toggleNavbar}
        >
          Weg2De Akademie
        </NAV>
      </NavLink>
      <NavLink>
        <NAV
          style={({ isActive }) => {
            return {
              fontWeight: isActive ? "600" : "",
              color: isActive ? "#ec1c24" : "",
            };
          }}
          to="/contact-us"
          onClick={toggleNavbar}
        >
          Contact Us
        </NAV>
      </NavLink>
      <PrimaryLink
        css={roundedHeaderButton && tw`rounded-full`}
        href="https://akademie.weg2de.com/login/index.php"
        onClick={toggleNavbar}
        target="_blank"
      >
        Student Login
      </PrimaryLink>
    </NavLinks>,
  ];
  // https://akademie.weg2de.com/login/index.php

  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink to="/">
      <img src={logo} alt="logo" style={{ width: "200px" }} />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {links}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};


const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
