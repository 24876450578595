import {
  HighlightedText,
  MainHeading,
  Subheading,
} from "components/misc/Headings";

import { BiSolidBarChartSquare } from "react-icons/bi";
import { FaClock } from "react-icons/fa6";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LuMonitor } from "react-icons/lu";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section`md:pr-6 relative flex-1`;
const RightColumn = tw.section`md:pl-4 relative flex-1`;
const Pill = tw.span`font-serif md:tracking-widest px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section`mt-5 flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const SubTitle = tw(Subheading)`text-lg tracking-widest`;
const Container = tw.section`max-w-screen-xl mx-auto`;

const OverviewSection = ({ heading, highlight }) => {
  return (
    <Container>
      <MainHeading>
        {heading}
        <HighlightedText>{highlight} </HighlightedText>
      </MainHeading>
      <TwoColumn>
        <LeftColumn>
          <Pill>OVERVIEW</Pill>

          <SectionDescription>
            The Weg2De Apprenticeship Preparatory Program (WAPP) is a
            comprehensive and meticulously structured preparatory course,
            designed to equip participants with the essential skills and
            knowledge required for a successful transition into the German
            workforce. This preparatory program spans two semesters, each
            focusing on key components essential for participants’ readiness and
            efficacy in pursuing an Ausbildung program.
          </SectionDescription>

          <Pill>What is Ausbildung?</Pill>

          <SectionDescription>
            Ausbildung is a German term that translates to "training" or
            "education."
            <br />
            In Germany, it typically refers to a vocational training program
            known as "dual vocational training" (Duale Ausbildung). This system
            combines classroom-based education at vocational schools
            (Berufsschule) with hands-on work experience at a company.
          </SectionDescription>
          <SectionDescription>
            <b>Dual System</b>: The Ausbildung program is characterized by its
            dual approach, where trainees split their time between school and
            practical work. This system ensures that students gain both
            theoretical knowledge and practical skills.
          </SectionDescription>
        </LeftColumn>
        <RightColumn>
          <Pill>KEY FACTS</Pill>
          <br />
          <br />
          <IconWrapper>
            <BiSolidBarChartSquare size={25} style={{ marginRight: "5px" }} />
            <SubTitle>A1 - B2</SubTitle>
          </IconWrapper>
          <SectionDescription>
            The Program covers Beginner to Advance Level of the German Language
            required for Apprenticeship
          </SectionDescription>
          <IconWrapper>
            <LuMonitor size={25} style={{ marginRight: "5px" }} />
            <SubTitle>Apprenticeship Placement</SubTitle>
          </IconWrapper>
          <SectionDescription>
            The main goal of the program is to prepare you for a an
            Apprenticeship (Ausbildung) in Germany. Weg2De will support you in
            landing your desired Apprenticeship position through our Recruiting
            & Career Services
          </SectionDescription>
          <IconWrapper>
            <FaRegCalendarAlt size={25} style={{ marginRight: "5px" }} />
            <SubTitle>3 Times yearly</SubTitle>
          </IconWrapper>
          <SectionDescription>
            The Courses starts 3 times yearly. In January, April & November to
            ensure you meet up with Start of Apprenticeship in Germany.
            <br />
            <br />
            <SubTitle>
            Next program starts: <Pill>November 11, 2024</Pill>
            </SubTitle>{" "}
          
          </SectionDescription>
          <IconWrapper>
            <FaClock size={25} style={{ marginRight: "5px" }} />
            <SubTitle>9 Months</SubTitle>
          </IconWrapper>
          <SectionDescription>
            Classes are 3 times a week, 3 units of 45 minutes per day with
            Evening or weekend classes option.
          </SectionDescription>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default OverviewSection;
