import "react-toastify/dist/ReactToastify.css";

import {
  HighlightedText,
  MainHeading,
  //SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import React, { } from "react";
import { ToastContainer, } from "react-toastify";


import { SectionDescription } from "components/misc/Typography";

import styled from "styled-components";
//import styles from "./Reg.module.css";
import tw from "twin.macro";


const Container = tw.div`relative`;
const MainTitle = tw(MainHeading)`text-left mb-5`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-5`;
const Column = tw.div`px-5 md:px-10   w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12  mt-5 md:mt-0 md:pr-10`,
]);
const RightContainer = tw.section`md:w-5/12 py-10 px-5 md:px-10 shadow-lg rounded md:rounded-r  bg-gray-100`;
const TextContent = tw.div` text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-left`;
//const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;


// eslint-disable-next-line
{/*const SubmitButton = tw(
  PrimaryButtonBase
)`bg-secondary-500 disabled:text-secondary-500 disabled:bg-secondary-300 hover:bg-secondary-700 py-4 font-serif text-white uppercase tracking-widest font-light text-sm inline-block mt-8`;*/}

//const SelectTitle = tw.p`mt-8 mb-2 text-secondary-500 text-sm md:text-lg font-serif tracking-widest`;
//const RadioWrapper = tw.div`font-serif text-sm md:text-lg tracking-wide text-gray-500`;
//const Error = tw.p`text-primary-500 font-serif `;
const Warning = tw.p`text-primary-500 mb-5 font-serif `;
const TAndC = tw(HighlightedText)`text-primary-500 underline italic font-serif `;
const SubHeadingTitle = tw(Subheading)`text-left text-sm md:text-lg`;
const Highlight = tw(
  HighlightedText
)`font-light uppercase text-secondary-500 font-serif`;

function RegistrationForm({ title }) {
 // const serviceID = process.env.REACT_APP_SERVICE_ID;
 // const courseTemplateID = process.env.REACT_APP_COURSE_TEMPLATE_ID;
  //const userID = process.env.REACT_APP_PUBLIC_ID;
 // const [loading, setLoading] = useState(false);

  //useEffect(() => emailjs.init(userID), []);
  // eslint-disable-next-line
{/*
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSend = async (data, e) => {
    e.preventDefault();
    const { name, email, phone, country, course, session, code, message } =
      data;

    try {
      setLoading(true);
      await emailjs.send(serviceID, courseTemplateID, {
        name: name.trim(),
        email: email.trim(),
        phone: phone.trim(),
        country: country.trim(),
        course: course.trim(),
        session: session.trim(),
        code: code.trim(),
        message: message.trim(),
      });
      toast.success("You have successfully registered for the course");
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong, try again!");
    } finally {
      setLoading(false);
    }
    e.target.reset();
  };
*/}
  return (
    <Container>
      <TwoColumn>
        <TextColumn>
          <TextContent>
            <Subheading>{title}</Subheading>
            <MainTitle id='register'>Register Now</MainTitle>
            <SubHeadingTitle>MINIMUM NUMBER OF PARTICIPANTS</SubHeadingTitle>
            <SectionDescription>
              The minimum class size is{" "}
              <HighlightedText>
                <strong> 6</strong>
              </HighlightedText>{" "}
              participants, for discounted courses 10. If this requirement is
              not met, the course will not proceed. In such an event, we will
              strive to provide you with an alternative option in a timely
              manner. Should you choose not to accept the alternative, any
              payments made will be promptly refunded.
            </SectionDescription>
            <SubHeadingTitle>PLACEMENT TEST</SubHeadingTitle>
            <SectionDescription>
              Please be aware of the following guidelines regarding our
              placement test. To ensure you are placed in the appropriate class,
              you will receive a placement test after booking your course. You
              must complete and return the test within 3 days. Completing and
              returning the placement test on time is mandatory for attending
              the course.
            </SectionDescription>
            <SubHeadingTitle>PAYMENT</SubHeadingTitle>
            <SectionDescription>
              <Highlight>
                <mark>
                  {" "}
                  Upon sending the form, you will receive an invoice and can Pay
                  with Visa or Mastercard, Direct debit or Bank transfer.
                </mark>
              </Highlight>
              <br/>
              <br/>
              <Highlight>
                <mark>
                  {" "}
                  Please, check your e-mail after registration for further instructions. 
                  
                  Registrations will be deleted after a week if no payment is made or no email communication from you!.
                </mark>
              </Highlight>
            </SectionDescription>
            <SubHeadingTitle>TERMS & CONDITIONS</SubHeadingTitle>
            <SectionDescription>
              By registering, you accept our{" "}
              <a href="/terms_and_conditions.pdf" target="_blank" rel="noopener noreferrer">
                <TAndC>Terms & Conditions</TAndC>
              </a>
            </SectionDescription>
           
          </TextContent>
        </TextColumn>
        <RightContainer>
        <Warning>THE COURSES ARE NOT FREE!!! Use this form only if you want to attend a course and you can pay the course fee listed above </Warning>
        <iframe
        title="Course Registration Form"
        aria-label="Course Registration Form"
        width="100%"
        height="750px"
        src="https://forms.weg2de.com/weg2de/form/CourseApplication/formperma/vRAf4do70VB1RwAEJNuRDfiA9GRBZCoUg6ePOgfQ-w8"
        frameborder="0"
        allowfullscreen=""
      >
        {" "}
      </iframe>
        </RightContainer>
      </TwoColumn>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="dark"
      />
    </Container>
  );
}

export default RegistrationForm;
