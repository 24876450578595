import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import CandidateLanding from "./CandidateLanding";
import FooterPage from "components/Footer";
import GermanLanguagePage from "../home/GermanLanguage";
import React from "react";
import SEO from "components/seo/SEO";
import ServicePortfolioPage from "./ServicePortfolio";
import pathway from "images/migration.svg";
import { portfolioServiceData } from "data/dummyData";
import prototypeIllustrationImageSrc from "images/work.svg";

function CandidatesPage() {
  return (
    <AnimationRevealPage>
       <SEO
        title="Candidates - Study, Work & do Apprenticeship in Germany - Your Pathway to Education & Career opportunities in Germany! - Weg2De"
        description="Weg2De - Find a Job in Germany - Find Ausbildung in Germany - Learn German Online - Study in Germany If you're considering moving to Germany or another country in the DACH region for work, study, or vocational training, you're in the right place "
        name="Weg2De - Candidates"
        type=""
      />
      <CandidateLanding imageSrc={pathway} showDecoratorBlob={true} />
      <ServicePortfolioPage
        heading="Service Portfolio"
        data={portfolioServiceData}
      />
      <GermanLanguagePage
        subheading="Candidate Pool"
        heading="Looking for a Job in Germany?"
        description="Are you an experienced professional with a minimum of 3 years in IT, Audiology, Nursing, or Engineering? We're looking for talented individuals like you to join our talent pool! Weg2De specializes in helping skilled professionals secure rewarding positions in Germany and across the DACH region.
        Sign up today and take the next step in your international career!"
        primaryButtonText="Join Now"
        primaryButtonUrl="https://zurl.to/tCr0"
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={true}
      />
      <FooterPage />
    </AnimationRevealPage>
  );
}

export default CandidatesPage;
