import { SectionHeading, Subheading } from "components/misc/Headings";

import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1 md:pt-16`;
const Pill = tw.span`font-serif md:tracking-widest px-2 md:px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const TwoColumn = tw.section`md:mt-5 flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center `;
const SubTitle = tw(Subheading)` tracking-widest`;

const CourseObjectivePage = () => {
  return (
    <TwoColumn>
      <LeftColumn>
        <SectionHeading>Course Objectives</SectionHeading>
        <SubTitle>
          <Pill>proficiency</Pill>
        </SubTitle>
        <SectionDescription>
          Develop working proficiency in German language skills: listening,
          speaking, reading, and writing.
        </SectionDescription>
        <SubTitle>
          <Pill>vocabulary & grammar</Pill>
        </SubTitle>
        <SectionDescription>
          Build vocabulary and grammar knowledge to construct sentences and
          engage in business related conversations.
        </SectionDescription>
      </LeftColumn>
      <RightColumn>
        <SubTitle>
          <Pill>confidence</Pill>
        </SubTitle>
        <SectionDescription>
          Gain confidence in using German in various real-life situations, such
          as interviews, working, e-mail writing etc.
        </SectionDescription>
        <SubTitle>
          <Pill> cultural understanding</Pill>
        </SubTitle>
        <SectionDescription>
          Enhance cultural understanding of German-speaking countries through
          language immersion activities and discussions.
        </SectionDescription>
      </RightColumn>
    </TwoColumn>
  );
};

export default CourseObjectivePage;
