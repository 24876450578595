import React from "react";
import { SectionDescription } from "components/misc/Typography";
import { SectionHeading } from "components/misc/Headings.js";
import styled from "styled-components";
import tw from "twin.macro";
const Container = tw.section`mt-10 md:mt-0 relative max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`-mt-3 flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)` md:w-5/12 flex-shrink-0  md:h-auto relative `;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const TextContent = tw.div` text-center md:text-left`;
const Description = tw.p`mt-3  leading-loose  text-sm md:text-base lg:text-lg text-gray-500 font-light text-justify `;
const HeadingTwo = tw(SectionHeading)` `;

function AboutCompany({ subheading, textOnLeft = false }) {
  return (
    <Container>
      {<HeadingTwo>{subheading}</HeadingTwo>}
      <TwoColumn>
        <ImageColumn>
          <SectionDescription>
          Seit 2018 steht Weg2De für höchste Qualität. 
          Als Experte für Personalvermittlung sind wir auf die Vermittlung von IT-Fachkräften, 
          Pflegekräften, Hörgeräteakustikern, Handwerkern und Auszubildenden aus aller Welt spezialisiert.
          </SectionDescription>

          <SectionDescription>
            Mit einem tiefgreifenden Verständnis der Anforderungen und
            Herausforderungen des deutschen Arbeitsmarktes sind wir der ideale Partner für Unternehmen und Bewerber. 
            Unser oberstes Ziel ist es, die optimale Verbindung zwischen qualifizierten 
            Fachkräften und passenden Unternehmen herzustellen – für eine langfristige, erfolgreiche Zusammenarbeit.
          </SectionDescription>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <SectionDescription>
            Wir gehen jedoch über die reine Vermittlung hinaus: 
            Unsere Dienstleistungen umfassen auch eine professionelle Unterstützung bei 
            Visumsverfahren und dem gesamten Umzugsprozess. Wir begleiten Fachkräfte und 
            Auszubildende Schritt für Schritt bei ihrem Start in Deutschland und der DACH-Region. 
            Dank unseres weitreichenden Netzwerks und unserer Erfahrung sorgen wir für einen 
            reibungslosen und effizienten Übergang – sowohl für die Talente als auch für die Unternehmen.
            </SectionDescription>
            <Description>
              Wenn Sie auf der Suche nach hochqualifizierten Fachkräften sind, stehen wir
              Ihnen gerne zur Verfügung. Lassen Sie uns gemeinsam Ihre Ziele verwirklichen und Ihr Unternehmen auf die nächste Stufe bringen!
            </Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
}

export default AboutCompany;
