import {
  HighlightedText,
  MainHeading,
} from "components/misc/Headings";

import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section` md:pl-5 relative flex-1`;
const Pill = tw.span`font-serif md:tracking-widest px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section`mt-5  flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;

const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`ml-3 md:ml-0 list-disc mt-2 md:list-none text-primary-500`;
const Container = tw.section`md:mt-10 max-w-screen-xl mx-auto`;

const OverviewSection = ({ heading, highlight }) => {
  return (
    <Container>
      <MainHeading>
        {heading}
        <HighlightedText>{highlight} </HighlightedText>
      </MainHeading>
      <TwoColumn>
        <LeftColumn>
        <Pill>German Health Insurances: An Overview</Pill>

<SectionDescription>
Germany has one of the most comprehensive healthcare systems in the world, 
ensuring that residents, workers, and students are well-protected. 
The system is divided into public (gesetzliche Krankenversicherung) 
and private (private Krankenversicherung) health insurance.
</SectionDescription>
<SectionDescription>
<b>Public Health Insurance</b> is mandatory for most employees and students. 
Contributions are based on income, and coverage includes general
healthcare, hospital visits, and prescriptions.
</SectionDescription>
<SectionDescription>
<b>Private Health Insurance</b> is an option for those who meet 
certain income thresholds or are self-employed. It often offers 
more flexibility, quicker access to specialists, and additional benefits.
</SectionDescription>
<SectionDescription>
Whether you’re employed, studying, or participating in a voluntary program like FSJ,
having valid health insurance is mandatory in Germany. 
Understanding your options is key to choosing the right plan for your needs.
</SectionDescription>

<SectionDescription>
At Weg2De, we provide tailored health insurance 
options to meet the needs of our clients in various situations. 
Whether you’re a student, worker, or here for a specific program, we’ve got you covered!
</SectionDescription>
          
        </LeftColumn>
        <RightColumn>
        <Pill>Insurances We Offer</Pill>
         <ListWrapper>
           <ListItem>
             <IconWrapper>
               <Icon>
                 <HiBadgeCheck size={20} />
               </Icon>
               <TextItem>
                 <b>Student Insurance</b>:
               </TextItem>
             </IconWrapper>
             <SectionDescription>
             Affordable public or private health insurance options designed specifically for international students studying in Germany.
             </SectionDescription>
           </ListItem>
           <ListItem>
             <IconWrapper>
               <Icon>
                 <HiBadgeCheck size={20} />
               </Icon>
               <TextItem>
                 <b>Work Insurance (Gesetzliche and Private)</b>:
               </TextItem>
             </IconWrapper>
             <SectionDescription>
             We help you choose between public or private health insurance plans, ensuring comprehensive coverage that aligns with your employment status.
             </SectionDescription>
           </ListItem>
           <ListItem>
             <IconWrapper>
               <Icon>
                 <HiBadgeCheck size={20} />
               </Icon>
               <TextItem>
                 <b>Insurance for Opportunity Card Holders</b>:
               </TextItem>
             </IconWrapper>
             <SectionDescription>
             Health insurance for those seeking opportunities in Germany under the Opportunity Card (Chancenkarte) program.
             </SectionDescription>
           </ListItem>
           <ListItem>
             <IconWrapper>
               <Icon>
                 <HiBadgeCheck size={20} />
               </Icon>
               <TextItem>
                 <b>Insurance for Ausbildung</b>:
               </TextItem>
             </IconWrapper>
             <SectionDescription>
             Specialized health insurance plans for vocational trainees (Azubis) during their apprenticeship programs.
             </SectionDescription>
           </ListItem>
           <ListItem>
             <IconWrapper>
               <Icon>
                 <HiBadgeCheck size={20} />
               </Icon>
               <TextItem>
                 <b>Insurance for FSJ Participants</b>:
               </TextItem>
             </IconWrapper>
             <SectionDescription>
             Coverage for those participating in a Freiwilliges Soziales Jahr (FSJ), ensuring you're protected throughout your voluntary service.
             </SectionDescription>
           </ListItem>
           <ListItem>
             <IconWrapper>
               <Icon>
                 <HiBadgeCheck size={20} />
               </Icon>
               <TextItem>
                 <b>Insurance for Language Course Participants</b>:
               </TextItem>
             </IconWrapper>
             <SectionDescription>
             We offer health insurance for those in Germany for language courses, ensuring compliance with visa requirements.
             </SectionDescription>
           </ListItem>

         </ListWrapper>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default OverviewSection;
