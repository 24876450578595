import {
  HighlightedText,
  MainHeading,
  Subheading,
} from "components/misc/Headings";

import { BiSolidBarChartSquare } from "react-icons/bi";
import { FaClock } from "react-icons/fa6";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LuMonitor } from "react-icons/lu";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section` md:pl-5 relative flex-1`;
const Pill = tw.span`font-serif md:tracking-widest px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section` mt-5 flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const SubTitle = tw(Subheading)`text-lg tracking-widest`;
const Container = tw.section`md:mt-10 max-w-screen-xl mx-auto`;

const OverviewSection = ({ heading, highlight }) => {
  return (
    <Container>
      <MainHeading>
        {heading}
        <HighlightedText>{highlight} </HighlightedText>
      </MainHeading>
      <TwoColumn>
        <LeftColumn>
          <Pill>KEY FACTS</Pill><br/><br/>
          <IconWrapper>
            <BiSolidBarChartSquare size={25} style={{ marginRight: "5px" }} />
            <SubTitle>A1 - B1 / B2</SubTitle>
          </IconWrapper>
          <SectionDescription>
            The Program covers Beginner to intermediate Level of the German
            Language
          </SectionDescription>
          <IconWrapper>
            <LuMonitor size={25} style={{ marginRight: "5px" }} />
            <SubTitle>Job Placement</SubTitle>
          </IconWrapper>
          <SectionDescription>
            The main goal of the program is to prepare you for a Job in Germany
            and DACH area. Weg2De will support you in landing your desired Job
            through our Recruiting & Career Services
          </SectionDescription>
          <IconWrapper>
            <FaRegCalendarAlt size={25} style={{ marginRight: "5px" }} />
            <SubTitle>Every Month</SubTitle>
          </IconWrapper>
          <SectionDescription>
            The Courses starts every month. Small class size for full students
            engagement and interaction.
            <br />
            <br />
           <SubTitle>Next program starts: <Pill>
              October 14th, 2024
            </Pill>{" "}</SubTitle>
            
          </SectionDescription>
          <IconWrapper>
            <FaClock size={25} style={{ marginRight: "5px" }} />
            <SubTitle>6 Months</SubTitle>
          </IconWrapper>
          <SectionDescription>
            Classes are 3 times a week, 3 units of 45 minutes per day with
            Evening or weekend classes option.
          </SectionDescription>
        </LeftColumn>
        <RightColumn>
          <Pill>PROGRAM DETAILS</Pill>
          <SectionDescription>
            This six-month German language course is specifically designed for
            IT professionals, healthcare workers, engineers and other
            professionals who aim to develop proficiency in German language
            skills tailored to their respective fields. Spanning from beginner
            level A1 to intermediate level B1, the course provides a
            comprehensive linguistic foundation while integrating
            industry-specific vocabulary and scenarios.
          </SectionDescription>
          <SectionDescription>
            Throughout the duration of the course, learners will engage in
            interactive and communicative language activities that focus on
            practical language use in IT, Engineering and healthcare contexts.
            Each session, scheduled three times a week with three units of 45
            minutes per day, is carefully structured to optimize learning
            outcomes while accommodating busy professional schedules.
          </SectionDescription>
          <SectionDescription>
            The course begins with an introduction to the German language,
            covering basic greetings, vocabulary, and essential grammar
            structures. As learners progress through the levels, they will
            acquire the language skills necessary for everyday interactions,
            professional communication, and specialized tasks within the IT,
            engineering and healthcare domain.
          </SectionDescription>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default OverviewSection;
