import AnimationRevealPage from "helpers/AnimationRevealPage";
import { CourseCustomerCount } from "data/dummyData";
import CourseFee from "./CourseFee";
import CourseObjectivePage from "./CourseObjectivePage";
import CustomerCountPage from "components/customer-count/CustomerCount";
import EntryRequirementPage from "./EntryRequirement";
import FooterPage from "components/Footer";
import GermanLanguagePage from "pages/home/GermanLanguage";
import KeyTopicsPage from "./KeyTopics";
import LangIMG from "images/learn-german.svg";
import OverviewSection from "./OverviewSection";
import ProfessionalSchedulePage from "./ProfessionalSchedule";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { courseFeeData } from "data/dummyData";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw.section` mt-32  relative`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

//meta title
document.title="Weg2De Akademie | German for Professionals | Learn German Online";

function AusbildungInGermanyPage() {
  return (
    <AnimationRevealPage>
      <Container>
        <OverviewSection heading="Ausbildung In " highlight='Germany' />
        <CourseObjectivePage />
        <KeyTopicsPage />
        <EntryRequirementPage />
        <CustomerCountPage data={CourseCustomerCount} />
        <ProfessionalSchedulePage />
        <CourseFee data={courseFeeData} />
        <GermanLanguagePage
          subheading="German Language"
          heading="Learn German with us Today"
          imageSrc={LangIMG}
          showDecoratorBlob={true}
          textOnLeft={false}
        />
        <FooterPage />
        <DecoratorBlob1 />
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
}

export default AusbildungInGermanyPage;
