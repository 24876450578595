import { HighlightedText, MainHeading, Subheading } from "components/misc/Headings";

import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section` md:pl-5 relative flex-1`;
const Pill = tw.span`font-serif md:tracking-widest px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section`mt-5  flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;

const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`list-disc mt-2 md:list-none text-primary-500`;
const Container = tw.section`md:mt-10 max-w-screen-xl mx-auto`;

const OverviewSection = ({ heading, highlight }) => {
  return (
    <Container>
      <MainHeading>
        {heading}
        <HighlightedText>{highlight} </HighlightedText>
      </MainHeading>
      <TwoColumn>
        <LeftColumn>
            <Pill>FSJ & BFD in Germany</Pill>
          <SectionDescription>
          Germany offers unique opportunities for young people and adults to engage in volunteer 
          work through the FSJ (Freiwilliges Soziales Jahr) and BFD (Bundesfreiwilligendienst) programs. 
          Both programs allow participants to contribute to society while gaining valuable personal and professional experiences.
          </SectionDescription>
          <Subheading>What is FSJ?</Subheading>
          <SectionDescription>
          <b>The Freiwilliges Soziales Jahr (FSJ)</b> is a voluntary social year aimed at young people between the ages of 16 and 27. 
          During the FSJ, participants work in social, cultural, or environmental institutions, such as hospitals, kindergartens, or care homes. 
          It's an excellent opportunity to learn new skills, gain work experience, and contribute to the community.
           The FSJ typically lasts for 6 to 12 months and can be extended to 18 months in special cases.
          </SectionDescription>
          <Subheading>What is BFD?</Subheading>
          <SectionDescription>
          <b>The Bundesfreiwilligendienst (BFD)</b> is a federal voluntary service that is open to people of all ages. 
          While similar to the FSJ, the BFD is more flexible in terms of the age range and the areas of service. 
          Whether you're looking for a career change, taking a break from studies, or simply want to contribute to society, 
          the BFD offers a wide range of opportunities in sectors like healthcare, education, and environmental conservation.
          </SectionDescription>
        </LeftColumn>
        <RightColumn>
         
            <Pill>Benefits of Participating</Pill>
         
          <ListWrapper>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Skill Development</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
              Both FSJ and BFD provide hands-on experience in various fields, 
              helping you develop practical skills and grow personally and professionally.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Civic Contribution</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
              By participating in these programs, you can give back to the community 
              and make a positive impact in fields that need your help.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Networking</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
              Build connections with professionals, mentors, 
              and peers, which can open doors to future employment opportunities.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Certificates and Recognitions</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
              Upon completion, participants receive a 
              certificate that can enhance your resume and serve as proof of your dedication and initiative.
              </SectionDescription>
            </ListItem>
            <Subheading>Why Choose FSJ or BFD?</Subheading>
              <SectionDescription>
              Whether you're a young adult exploring career options or someone looking to make a meaningful contribution, FSJ and BFD provide fulfilling experiences that can shape your future while making a difference in society. 
              These programs are not just about volunteer work—they're about personal growth, learning, and community service.
              </SectionDescription>
          </ListWrapper>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default OverviewSection;
