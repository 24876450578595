import AnimationRevealPage from "helpers/AnimationRevealPage";
import CourseFee from "./CourseFee";
import EntryRequirementPage from "./EntryRequirement";
import FooterPage from "components/Footer";
import { G4pcourseFeeData } from "data/dummyData";
import GermanLanguagePage from "pages/home/GermanLanguage";
import KeyTopicsPage from "./KeyTopics";
import LangIMG from "images/professional.svg";
import OverviewSection from "./OverviewSection";
import SEO from "components/seo/SEO";
//import ProfessionalSchedulePage from "./ProfessionalSchedule";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw.section` mt-32  relative`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;


function ProfessionalCoursePage() {
  return (
    <AnimationRevealPage>
      <SEO
        title="German for Professionals - Weg2De Akademie"
        description="German for Professionals | Learn German Online | German Individual Courses | German for Professionals "
        name="German for Professionals"
        type=""
      />
      <Container>
        <OverviewSection heading="German For " highlight='Professionals' />
        <KeyTopicsPage />
        <EntryRequirementPage />
        <CourseFee data={G4pcourseFeeData} />
        <GermanLanguagePage
          subheading="German for Professionals"
          heading="Do you want to Signup?"
          description="Click enroll now to fill our registration form for the German for professionals program and we will get back to you shortly"
          imageSrc={LangIMG}
          showDecoratorBlob={true}
          textOnLeft={false}
          primaryButtonUrl="/contact-us"
        />
        <FooterPage />
        <DecoratorBlob1 />
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
}

export default ProfessionalCoursePage;
