import { HighlightedText, MainHeading } from "components/misc/Headings";

import AnimationRevealPage from "helpers/AnimationRevealPage";
import FaqPage from "components/FAQ";
import FooterPage from "components/Footer";
import React from "react";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { generalFaqs } from "data/dummyData";
import SEO from "components/seo/SEO"
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.section`mt-32 relative max-w-screen-xl mx-auto`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

function GeneralFaqsPage() {
  return (
    <AnimationRevealPage>
      <SEO
        title="FAQs - Weg2De - Your Pathway to Education & Career Opportunities in Germany"
        description="FAQs - Weg2De - German Online Group Course | Learn German Online | German Individual Courses | German for Professionals "
        name="Weg2De"
        type=""
      />
      <Container>
        <MainHeading>
           <HighlightedText>Faqs</HighlightedText>
        </MainHeading>
        <FaqPage
          subheading="FAQS"
          heading="Do you have Questions?"
          faqs={generalFaqs}
        />
        <DecoratorBlob1 />
      </Container>
      <FooterPage />
    </AnimationRevealPage>
  );
}

export default GeneralFaqsPage;
