import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import tw from "twin.macro";
const LeftColumn = tw.section`md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1`;
const TwoColumn = tw.section`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const IconWrapper = tw.div` flex text-primary-500`;
const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`ml-3 md:ml-0 list-disc md:list-none text-primary-500`;
const Pill = tw.div`font-serif text-sm md:text-lg uppercase md:tracking-widest leading-none px-3 md:px-5 my-5 py-1 rounded-full text-white text-left bg-primary-500 inline-block `;
//const ButtonContainer = tw.section`my-5 flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto`;
//const Link = tw.a``;
const EntryRequirementPage = () => {
  return (
    <>
      <TwoColumn>
        <LeftColumn>
          <Pill>Entry Requirements:</Pill>
          <ListWrapper>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  Applicants should have completed a Bachelors degree or a
                  minimum of 2 years vocational training in (Nursing, Medicine,
                  Pharmacy, IT, Engineering, Surveying, Architecture etc.)
                </TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>Have minimum of 2 years working experience</TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  For IT Professionals without degree, minimum of 3 years
                  working experience
                </TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>English Language skills on Level B1</TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  Be interested in learning the German language and working in
                  Germany, Austria or Switzerland
                </TextItem>
              </IconWrapper>
            </ListItem>
          </ListWrapper>
        </LeftColumn>
        <RightColumn>
          <Pill>Reasons to enroll in this program:</Pill>
          <ListWrapper>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>Enhanced job opportunities</TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  Preparation for job interviews and career success in Germany
                </TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  Tap into our network of employers and job openings
                </TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>English Language skills on Level B1</TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  Achieve B1 level German proficiency, essential for permanent
                  residency. Plus, with the EU Blue Card and German on B1 Level,
                  you can apply for PR in just 2 years, or 3 years with a
                  regular work permit.
                </TextItem>
              </IconWrapper>
            </ListItem>
          </ListWrapper>
        </RightColumn>
      </TwoColumn>
      {/*<ButtonContainer>
        <NavLink href="#available-classes">View Available Classes</NavLink>
      </ButtonContainer>*/}
    </>
  );
};

export default EntryRequirementPage;
