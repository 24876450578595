import AnimationRevealPage from "helpers/AnimationRevealPage";
import FooterPage from "components/Footer";
import OurTeam from "./OurTeam";
import React from "react";
import { teamData } from "data/dummyData";
import tw from "twin.macro";

const Container = tw.section`mt-10`;

const OurTeamPage = () => {
  return (
    <AnimationRevealPage>
      <Container>
        <OurTeam heading="Our Team" team={teamData} />
      </Container>
      <FooterPage />
    </AnimationRevealPage>
  );
};

export default OurTeamPage;
