import React from "react";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.section`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`mb-10 flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-between max-w-screen-xl mx-auto`}
`;
const Heading = tw(SectionHeading)`font-serif text-center mt-10 -mb-5`;

const Card = styled.div`
  ${tw`z-20 w-[100%] md:w-[40%] lg:w-[24%] flex flex-col items-center px-6 py-10 shadow-special rounded-lg mt-12`}
  .imageContainer {
    ${tw`text-center rounded-full px-6 flex-shrink relative`}
    img {
      ${tw`h-20`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-serif capitalize font-medium text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-light text-gray-500 text-sm text-justify leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const CompanyServicesPage = ({ data, heading }) => {
  return (
    <Container>
      <Heading>{heading}</Heading>
      <ThreeColumnContainer>
        {data?.map((card, i) => (
            <Card key={i}>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
              </span>
            </Card>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};

export default CompanyServicesPage;
