import { HighlightedText, MainHeading } from "components/misc/Headings";

import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section` md:pl-5 relative flex-1`;
const Pill = tw.span`font-serif md:tracking-widest px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section`mt-5  flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;

const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p` text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`ml-3 md:ml-0 list-disc mt-2 md:list-none text-primary-500`;
const Container = tw.section`md:mt-10 max-w-screen-xl mx-auto`;

const OverviewSection = ({ heading, highlight }) => {
  return (
    <Container>
      <MainHeading>
        {heading}
        <HighlightedText>{highlight} </HighlightedText>
      </MainHeading>
      <TwoColumn>
        <LeftColumn>
          <Pill>PROGRAM DETAILS</Pill>
          <SectionDescription>
            Finding the right job in Germany can be a complex and competitive
            process, especially if you’re new to the country or unfamiliar with
            the local job market. Our Job Search Workshop is specifically
            designed to provide you with a step-by-step guide to navigating the
            German job market, helping you increase your chances of landing your
            dream job.
          </SectionDescription>
          <SectionDescription>
            Whether you’re just starting your career, looking to change
            industries, or aiming for a fresh start in Germany, this workshop
            covers everything you need to know. From understanding where to find
            the best job opportunities to mastering the art of German-style CV
            writing, our goal is to empower you with the skills and confidence
            needed for a successful job search. We’ll walk you through the
            entire process, providing practical tips and expert insights to give
            you a competitive edge in today’s job market.
          </SectionDescription>
          <SectionDescription>
            With a focus on effective strategies and tailored advice, this
            workshop ensures you’re well-prepared at every stage of your job
            search journey, helping you present yourself as the ideal candidate
            to potential employers in Germany.
          </SectionDescription>
        </LeftColumn>
        <RightColumn>
          <Pill>What’s Included?</Pill>
          <ListWrapper>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Job Market Insights</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Learn about the German job market, including where to find job
                listings, top industries, and key hiring trends.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>CV Optimization</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Get expert advice on how to craft a CV that stands out to German
                employers, including layout, formatting, and essential content
                tailored to the German job market.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Application Techniques</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Discover best practices for writing effective cover letters and
                submitting job applications that get noticed.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Interview Preparation</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Receive tips and strategies to ace your job interviews,
                including how to answer common questions, the dos and don’ts of
                German business etiquette, and how to confidently present your
                skills.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Networking Tips</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Learn how to leverage networking and professional platforms like
                LinkedIn to connect with potential employers in Germany.
              </SectionDescription>
            </ListItem>
          </ListWrapper>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default OverviewSection;
