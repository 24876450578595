import { careerCoachingData, germanCoursesData } from "../../data/dummyData";

import AcademyLandingPage from "./AcademyLanding";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import CoursesSection from "./CoursesSection";
import FooterPage from "components/Footer";
import React from "react";
import SEO from "components/seo/SEO";
import GermanLanguagePage from "pages/home/GermanLanguage";
import prototypeIllustrationImageSrc from "images/work.svg"
//eslint-disable-next-line
import tw from "twin.macro";

const Container = tw.section`mt-20 relative`;

function AcademyPage() {
  return (
    <AnimationRevealPage>
       <SEO
        title="Weg2De Akademie - Learn German Online"
        description="Learn German Online | German Online Group Course | German Individual Courses | German for Professionals | Best Online German School"
        name="Weg2De Akademie - Learn German Online"
        type=""
      />
      <Container>
          <AcademyLandingPage />
          <CoursesSection
            data={germanCoursesData}
            heading="German Courses"
            description="Discover German Language and Orientation Courses tailored to your
          specific needs at Weg2De Akademie!"
          />
          <CoursesSection
            data={careerCoachingData}
            heading="Career Coaching"
            description="There will be career coaching description here"
          />

        <GermanLanguagePage
        subheading="Candidate Pool"
        heading="Looking for a Job in Germany?"
        description="Are you an experienced professional with a minimum of 3 years in IT, Audiology, Nursing, or Engineering? We're looking for talented individuals like you to join our talent pool! Weg2De specializes in helping skilled professionals secure rewarding positions in Germany and across the DACH region.
        Sign up today and take the next step in your international career!"
        primaryButtonText="Join Now"
        primaryButtonUrl="https://zurl.to/tCr0"
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={true}
      />
      </Container>
      <FooterPage />
    </AnimationRevealPage>
  );
}

export default AcademyPage;
