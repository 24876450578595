import React from "react";
import { SectionDescription } from "components/misc/Typography";
import { SectionHeading } from "components/misc/Headings";
import tw from "twin.macro";

const LeftColumn = tw.section`md:pr-5 relative flex-1`;
const TwoColumn = tw.section`mt-10 flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center `;


const KeyTopicsPage = () => {
  return (
    <TwoColumn>
      <LeftColumn>
        <SectionHeading>Why Choose Our Coaching Service?</SectionHeading>
        <SectionDescription>
        Our Working and Living in Germany Coaching Service goes beyond basic relocation advice. 
        We are committed to providing you with ongoing support as you build your life in Germany, 
        empowering you to thrive both personally and professionally. With our guidance, 
        you can overcome common challenges, avoid pitfalls, 
        and make the most of your new opportunities in Germany.
        </SectionDescription>
     </LeftColumn>
    </TwoColumn>
  );
};

export default KeyTopicsPage;
