import { SectionHeading, Subheading } from "components/misc/Headings.js";

import { PrimaryButtonLink } from "components/misc/Links";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.div`relative  mt-20`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = tw(
  SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-secondary-500 -z-10`;

const Wrapper = tw.div`mt-10 text-center lg:text-left`;

function GermanLanguagePage({
  subheading,
  heading,
  description = "Discover German language and orientation courses tailored to your specific travel objectives at Weg2De Akademie. Explore our offerings based on the purpose of your journey to Germany by clicking the link below.",
  primaryButtonText = "Enroll Now",
  primaryButtonUrl = "/akademie",
  imageSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = false,
  textOnLeft = true,
}) {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
          {showDecoratorBlob && <DecoratorBlob />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <Subheading>{subheading}</Subheading>
          <SectionHeading>{heading}</SectionHeading>
          <SectionDescription>{description}</SectionDescription>
          <Wrapper>
            <PrimaryButtonLink
              buttonRounded={buttonRounded}
              to={primaryButtonUrl}
            >
              {primaryButtonText}
            </PrimaryButtonLink>
          </Wrapper>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
}

export default GermanLanguagePage;
