import { HighlightedText, Subheading } from "components/misc/Headings";

import { FaExternalLinkAlt } from "react-icons/fa";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
//eslint-disable-next-line
import tw from "twin.macro";

const ListWrapper = tw.ul`list-disc marker:text-primary-500 text-gray-500 font-light text-sm md:text-lg`;
const ListItem = tw.li`mb-2`;
const LinkWrapper = tw.a`flex items-center mb-5 text-gray-500 font-light underline`;
const SubTitle = tw(Subheading)`capitalize text-sm md:text-lg`;

const paymentAns = (
  <>
    <ListWrapper>
      <SubTitle>For this purpose we use the following data:</SubTitle>
      <ListItem>A first and last name</ListItem>
      <ListItem>Account name or alias</ListItem>
      <ListItem>
        A home or other physical address, including street name and name of a
        city or town
      </ListItem>
      <ListItem>An email address</ListItem>
      <ListItem>A telephone number</ListItem>
      <ListItem>
        Financial information such as bank account number or credit card number
      </ListItem>
      <ListItem>IP Address</ListItem>
    </ListWrapper>
    <SubTitle>The basis on which we may process these data is:</SubTitle>
    <LinkWrapper
      href="https://cookiedatabase.org/legal-bases/#consent"
      target="_blank"
    >
      Upon the provision of consent{" "}
      <HighlightedText>
        {" "}
        <FaExternalLinkAlt size={10} style={{ marginLeft: "5px" }} />
      </HighlightedText>
    </LinkWrapper>

    <SubTitle>Retention period</SubTitle>
    <SectionDescription>
      We retain this data until the service is terminated.
    </SectionDescription>
  </>
);

const contactAns = (
  <>
    <ListWrapper>
      <SubTitle>For this purpose we use the following data:</SubTitle>
      <ListItem>A first and last name</ListItem>
      <ListItem>Account name or alias</ListItem>
      <ListItem>An email address</ListItem>
      <ListItem>A telephone number</ListItem>
      <ListItem>
        A home or other physical address, including street name and name of a
        city or town
      </ListItem>
    </ListWrapper>
    <SubTitle>The basis on which we may process these data is:</SubTitle>
    <LinkWrapper
      href="https://cookiedatabase.org/legal-bases/#consent"
      target="_blank"
    >
      Upon the provision of consent{" "}
      <HighlightedText>
        {" "}
        <FaExternalLinkAlt size={10} style={{ marginLeft: "5px" }} />
      </HighlightedText>
    </LinkWrapper>
    <SubTitle>Retention period</SubTitle>
    <SectionDescription>
      We retain this data until the service is terminated.
    </SectionDescription>
  </>
);

const registerAns = (
  <>
    <ListWrapper>
      <SubTitle>For this purpose we use the following data:</SubTitle>
      <ListItem>A first and last name</ListItem>
      <ListItem>Account name or alias</ListItem>
      <ListItem>An email address</ListItem>
      <ListItem>A telephone number</ListItem>
      <ListItem>Photos</ListItem>
      <ListItem>Sex</ListItem>
    </ListWrapper>
    <SubTitle>The basis on which we may process these data is:</SubTitle>
    <LinkWrapper
      href="https://cookiedatabase.org/legal-bases/#consent"
      target="_blank"
    >
      Upon the provision of consent{" "}
      <HighlightedText>
        {" "}
        <FaExternalLinkAlt size={10} style={{ marginLeft: "5px" }} />
      </HighlightedText>
    </LinkWrapper>
    <SubTitle>Retention period</SubTitle>
    <SectionDescription>
      We retain this data until the service is terminated.
    </SectionDescription>
  </>
);

const PAndS = (
  <>
    <ListWrapper>
      <SubTitle>For this purpose we use the following data:</SubTitle>
      <ListItem>A first and last name</ListItem>
      <ListItem>Account name or alias</ListItem>
      <ListItem>An email address</ListItem>
    </ListWrapper>
    <SubTitle>The basis on which we may process these data is:</SubTitle>
    <LinkWrapper
      href="https://cookiedatabase.org/legal-bases/#consent"
      target="_blank"
    >
      Upon the provision of consent{" "}
      <HighlightedText>
        {" "}
        <FaExternalLinkAlt size={10} style={{ marginLeft: "5px" }} />
      </HighlightedText>
    </LinkWrapper>
    <SubTitle>Retention period</SubTitle>
    <SectionDescription>
      We retain this data until the service is terminated.
    </SectionDescription>
  </>
);

const supportService = (
  <>
    <ListWrapper>
      <SubTitle>For this purpose we use the following data:</SubTitle>
      <ListItem>A first and last name</ListItem>
      <ListItem>Account name or alias</ListItem>
      <ListItem>
        A home or other physical address, including street name and name of a
        city or town
      </ListItem>
      <ListItem>An email address</ListItem>
      <ListItem>A telephone number</ListItem>
      <ListItem>IP Address</ListItem>
    </ListWrapper>
    <SubTitle>The basis on which we may process these data is:</SubTitle>
    <LinkWrapper
      href="https://cookiedatabase.org/legal-bases/#consent"
      target="_blank"
    >
      Upon the provision of consent{" "}
      <HighlightedText>
        {" "}
        <FaExternalLinkAlt size={10} style={{ marginLeft: "5px" }} />
      </HighlightedText>
    </LinkWrapper>
    <SubTitle>Retention period</SubTitle>
    <SectionDescription>
      We retain this data until the service is terminated.
    </SectionDescription>
  </>
);

const newsLetter = (
  <>
    <ListWrapper>
      <SubTitle>For this purpose we use the following data:</SubTitle>
      <ListItem>A first and last name</ListItem>
      <ListItem>Account name or alias</ListItem>
      <ListItem>An email address</ListItem>
      <ListItem>A telephone number</ListItem>
    </ListWrapper>
    <SubTitle>The basis on which we may process these data is:</SubTitle>
    <LinkWrapper
      href="https://cookiedatabase.org/legal-bases/#consent"
      target="_blank"
    >
      Upon the provision of consent{" "}
      <HighlightedText>
        {" "}
        <FaExternalLinkAlt size={10} style={{ marginLeft: "5px" }} />
      </HighlightedText>
    </LinkWrapper>
    <SubTitle>Retention period</SubTitle>
    <SectionDescription>
      We retain this data until the service is terminated.
    </SectionDescription>
  </>
);

export const privacyFaqData = [
  { id: 1, question: "1.1 Payments", answer: paymentAns },
  {
    id: 2,
    question: "1.2 Contact – Through phone, mail, email and/or webforms",
    answer: contactAns,
  },
  {
    id: 3,
    question: "1.3 Registering an account",
    answer: registerAns,
  },
  {
    id: 4,
    question: "1.4 To be able to offer personalized products and services",
    answer: PAndS,
  },
  {
    id: 5,
    question:
      "1.5 To support services or products that a customer wants to buy or has purchased",
    answer: supportService,
  },
  {
    id: 6,
    question: "1.6 Newsletters",
    answer: newsLetter,
  },
];
