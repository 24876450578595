import { HighlightedText, MainHeading } from "components/misc/Headings";

import IMG from "images/hr.svg";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.section`
  ${tw`mt-32`}
`;

const TwoColumn = tw.section`flex flex-col lg:flex-row  max-w-screen-xl mx-auto`;
const LeftColumn = tw.section`relative  md:w-8/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.section`relative md:w-4/12 my-5 lg:mt-0 flex-1 flex flex-col justify-center lg:self-center`;
const Notification = tw.div` mt-4 mb-10 pl-3 py-1 text-gray-500 border-l-4 text-left border-primary-500 font-medium text-sm`;
const Wrapper = tw.section`max-w-screen-xl mx-auto`;

function CompanyLanding() {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <MainHeading>
            AUF DER SUCHE NACH HOCHMOTIVIERTEN <br />
            <HighlightedText>FACHKRÄFTEN</HighlightedText> UND{" "}
            <HighlightedText>AZUBIS?</HighlightedText>
          </MainHeading>
          <Wrapper>
            <Notification>Weg2De bietet die ideale Lösung!</Notification>
          </Wrapper>
        </LeftColumn>
        <RightColumn>
          <img
            tw="min-w-0 w-full max-w-lg  h-full"
            src={IMG}
            alt="2 men and a lady standing representation of company's values"
          />
        </RightColumn>
      </TwoColumn>
    </Container>
  );
}

export default CompanyLanding;
