import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import { SectionHeading, } from "components/misc/Headings";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1`;
//const Pill = tw.span`font-serif md:tracking-widest px-2 md:px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const TwoColumn = tw.section`md:mt-5 flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
//const SubTitle = tw(Subheading)` tracking-widest`;
const IconWrapper = tw.div` flex text-primary-500`;
const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`ml-3 md:ml-0 list-disc md:list-none text-primary-500`;
const Container = tw.section`max-w-screen-xl mx-auto`;

const BenefitsPage = () => {
  return (
    <Container>
    <TwoColumn>
      <LeftColumn>
        <SectionHeading>Benefits of Ausbildung</SectionHeading>
          <ListWrapper>
              <ListItem>
                <IconWrapper>
                  <Icon>
                    <HiBadgeCheck size={20} />
                  </Icon>
                  <TextItem>
                  <b>Practical Experience</b> 
                  </TextItem>
                </IconWrapper>
              </ListItem>
              <SectionDescription>
              Trainees gain significant hands-on experience, 
              making them job-ready upon graduation.
              </SectionDescription>

              <ListItem>
                <IconWrapper>
                  <Icon>
                    <HiBadgeCheck size={20} />
                  </Icon>
                  <TextItem>
                  <b>Earning While Learning</b> 
                  </TextItem>
                </IconWrapper>
              </ListItem>
              <SectionDescription>
              Trainees receive a monthly stipend or salary, 
              which increases each year.
              </SectionDescription>
      
              <ListItem>
                <IconWrapper>
                  <Icon>
                    <HiBadgeCheck size={20} />
                  </Icon>
                  <TextItem>
                  <b>Employment Opportunities</b> 
                  </TextItem>
                </IconWrapper>
              </ListItem>
              <SectionDescription>
              Many companies offer permanent positions to 
              their trainees after successful completion of the program.
              </SectionDescription>
          </ListWrapper>
      </LeftColumn>
      <RightColumn>
      <SectionHeading>Positions to look out for</SectionHeading>
          <ListWrapper>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                <b>IT Specialist (Fachinformatiker)</b>: 
                Specializations include application development, system integration, data etc
                </TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem><b>Industrial Mechanic (Industriemechaniker)</b>: 
                Focuses on the assembly, maintenance, and repair of machinery.</TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                <b>Bank Clerk (Bankkaufmann/-frau)</b>: Training in financial services and customer advising
                </TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem><b>Nurse (Pflegefachmann/-frau)</b>: Comprehensive training in healthcare at hospitals, care homes etc.</TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                <b>Automotive Technician (Kfz-Mechatroniker)</b>: 
                Specializes in vehicle maintenance and repair
                </TextItem>
              </IconWrapper>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                <b>Retail Salesperson (Einzelhandelskaufmann/-frau)</b>: Focuses on customer service and sales in the retail sector. 
                </TextItem>
              </IconWrapper>
            </ListItem>
          </ListWrapper>
      </RightColumn>
    </TwoColumn>
    </Container>
  );
};

export default BenefitsPage;
