import { SectionHeading, Subheading } from "components/misc/Headings";

import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1 md:pt-16`;
//const Pill = tw.span`font-serif md:tracking-widest px-2 md:px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const TwoColumn = tw.section`md:mt-5 flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center `;
const SubTitle = tw(Subheading)` tracking-widest`;

const CourseObjectivePage = () => {
  return (
    <TwoColumn>
      <LeftColumn>
        <SectionHeading>What We Offer</SectionHeading>
        <SubTitle>
          <Subheading>German Language Training</Subheading>
        </SubTitle>
        <SectionDescription>
        Gain the required German proficiency through our tailored language courses.
        </SectionDescription>
        <SubTitle>
          <Subheading>Application Assistance</Subheading>
        </SubTitle>
        <SectionDescription>
        We guide you in preparing your CV, cover letter, and necessary documents for a strong application.
        </SectionDescription>
      </LeftColumn>
      <RightColumn>
        <SubTitle>
          <Subheading>Position Search Support</Subheading>
        </SubTitle>
        <SectionDescription>
        Receive help finding and applying for FSJ or BFD roles that match your interests.
        </SectionDescription>
        <SubTitle>
          <Subheading> Travel & Accommodation</Subheading>
        </SubTitle>
        <SectionDescription>
        Every journey is unique, so we tailor our coaching sessions to your individual needs, 
        goals, and concerns. Whether you need help with job applications, navigating German bureaucracy, 
        or understanding cultural nuances, 
        your coach will provide personalized advice and support to ensure your success.
        </SectionDescription>
      </RightColumn>
    </TwoColumn>
  );
};

export default CourseObjectivePage;
