import React from "react";
import { SectionHeading } from "components/misc/Headings";
import styled from "styled-components";
import tw from "twin.macro";

const Pill = tw.span`md:mr-1 font-serif tracking-widest py-2 px-10 my-4 text-sm md:text-lg rounded-full bg-secondary-500 text-left text-white inline-block`;
const ThreeColumnContainer = styled.div`
  ${tw`mb-10  md:mb-0 flex flex-col items-center  md:flex-row flex-wrap md:justify-between max-w-screen-xl mx-auto`}
`;
const Tile = tw.div`text-sm md:text-lg font-serif bg-white rounded-full text-primary-500 border border-secondary-500 py-2 px-10 inline-block mt-3 leading-tight`;
const Text = tw.p`mt-3 text-left font-serif text-gray-500 mb-5`;
const Time = tw.span`text-primary-500 font-normal tracking-widest`;
const Container = tw.section`max-w-screen-xl mx-auto mt-10`;

const Card = styled.section((props) => [
  tw`w-[90%] md:w-[25%] lg:w-[30%] mb-5 font-light px-5  text-secondary-500 cursor-pointer flex flex-col items-center py-10  shadow-special rounded-lg`,
]);

const Title = tw.h3`text-sm md:text-lg mb-5 font-medium font-serif text-secondary-500 px-4 md:px-5 py-2 rounded-full  shadow-xs`;

const ProfessionalSchedulePage = () => {
  return (
    <Container id="available-classes">
      <SectionHeading>German for Professionals – Schedules</SectionHeading>
      <ThreeColumnContainer>
        <Card secondary>
          <Time>2 Months</Time>
          <Title>Online Weekend Morning</Title>
          <Time>10:00am - 1:00pm</Time>
          <Pill>8 week course</Pill>
          <Text>
            {" "}
            Each session consists of 3 units, with each unit lasting 45 minutes
          </Text>
          <Tile>2 times per week</Tile>
        </Card>
        <Card secondary>
          <Time>2 Months</Time>
          <Title>Online Evening Classes</Title>
          <Time>6:00pm to 8:30pm</Time>
          <Pill>8 week course</Pill>
          <Text>
            Each session consists of 3 units, with each unit lasting 45 minutes
          </Text>
          <Tile>3 times per week</Tile>
        </Card>
        <Card secondary>
          <Time>2 Months</Time>
          <Title>Online Weekend Evening</Title>
          <Time>6:00pm to 8:30pm</Time>
          <Pill>8 week course</Pill>
          <Text>
            Each session consists of 3 units, with each unit lasting 45 minutes
          </Text>
          <Tile>2 times per week</Tile>
        </Card>
      </ThreeColumnContainer>
    </Container>
  );
};

export default ProfessionalSchedulePage;
