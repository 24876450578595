import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.section`
  ${tw`mt-20 max-w-screen-xl mx-auto`}
`;

const AppointmentBookingPage = () => {
  return (
    <Container>
      <iframe
        title="Talent Finden"
        aria-label="Talent Finden"
        width="100%"
        height="1170px"
        src="https://forms.weg2de.com/weg2de/form/ClientDetails/formperma/EFARoKrI9Kc670-Iqd5qpUZ1oMLzGZjez5WPm1nnkKE?zf_lang=de"
        frameborder="0"
        allowfullscreen=""
      >
        {" "}
      </iframe>
    </Container>
  );
};

export default AppointmentBookingPage;


