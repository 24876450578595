import { HighlightedText, MainHeading } from "components/misc/Headings";

import AcademyImg from "images/online_learning.svg";
import React from "react";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.section`relative`;
const TwoColumn = tw.section`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-5 md:py-10`;
const RightColumn = tw.section`relative lg:w-8/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const LeftColumn = tw.section`relative mt-12 lg:w-4/12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-center`;
const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;
const Description = tw.p`mt-8 text-justify md:text-left text-sm md:text-base lg:text-lg font-light leading-loose text-gray-500 md:pr-40`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
//meta title
document.title = "Weg2De Akademie | Learn German Online";

function AcademyLandingPage() {
  return (
    <>
      <Container>
        <TwoColumn>
          <RightColumn>
            <MainHeading>Learn <HighlightedText>German</HighlightedText> Online @ Weg2De <HighlightedText>Akademie</HighlightedText> </MainHeading>
            <Description>
            At Weg2De Akademie, we offer customized German language and orientation courses designed to meet your unique needs. Whether you're preparing to study, work, or live in a German-speaking country, our courses will equip you with the language skills and cultural understanding necessary for a successful transition.{" "}
            </Description>
          </RightColumn>
          <LeftColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl h-full"
                src={AcademyImg}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </LeftColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
}

export default AcademyLandingPage;
