import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import { SectionHeading, } from "components/misc/Headings";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1`;
//const Pill = tw.span`font-serif md:tracking-widest px-2 md:px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const TwoColumn = tw.section`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
//const SubTitle = tw(Subheading)` tracking-widest`;
const Container = tw.section`max-w-screen-xl mx-auto`;

const IconWrapper = tw.div` flex text-primary-500`;
const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`ml-3 md:ml-0 list-disc md:list-none text-primary-500`;
//const Pill = tw.div`font-serif text-sm md:text-lg uppercase md:tracking-widest leading-none px-3 md:px-5 my-5 py-1 rounded-full text-white text-left bg-primary-500 inline-block `;
//const Center = tw.div`mt-10 flex justify-center items-center`;

const CourseObjectivePage = () => {
  return (
    <Container>
        <TwoColumn>
          <LeftColumn>
          <SectionHeading>Ausbildung Highlights</SectionHeading>
            <SectionDescription>
            The duration of Ausbildung programs usually ranges from 2 to 3.5 years, 
            depending on the occupation and the trainee's prior qualifications.
            </SectionDescription>

            <SectionDescription>
            Upon successful completion, trainees receive a recognized certification (Gesellenbrief) 
            from the responsible body like German Chamber of Commerce and Industry (IHK) or the Chamber of Crafts (HWK).
            <br/>
            The Certificate is recognized can open doors to international job markets, especially within the EU.
            </SectionDescription>

          </LeftColumn>

          <RightColumn>
          <SectionHeading>Entry Requirements</SectionHeading>
              <ListWrapper>
                <ListItem>
                  <IconWrapper>
                    <Icon>
                      <HiBadgeCheck size={20} />
                    </Icon>
                    <TextItem><b>High School Diploma</b>: You must have completed high School</TextItem>
                  </IconWrapper>
                </ListItem>
                <ListItem>
                  <IconWrapper>
                    <Icon>
                      <HiBadgeCheck size={20} />
                    </Icon>
                    <TextItem>
                    <b>German Language Skills</b>: 
                    To enter the WAPP program, you do not need prior a knowledge of German
                    However, to get an Apprenticeship position, German on minimum B1/B2 Level is required.
                    </TextItem>
                  </IconWrapper>
                </ListItem>
                <ListItem>
                  <IconWrapper>
                    <Icon>
                      <HiBadgeCheck size={20} />
                    </Icon>
                    <TextItem>
                    <b>Motivation & Interest</b>: You should be interested in your chosen in the selected prospective career.
                    </TextItem>
                  </IconWrapper>
                </ListItem>
                <ListItem>
                  <IconWrapper>
                    <Icon>
                      <HiBadgeCheck size={20} />
                    </Icon>
                    <TextItem>
                    <b>Willingnes to learn</b>: German Employers are searching for motivated and creative Apprentice
                    </TextItem>
                  </IconWrapper>
                </ListItem>
              </ListWrapper>
          </RightColumn>
        </TwoColumn>
    </Container>
    
  );
};

export default CourseObjectivePage;
