import AnimationRevealPage from "helpers/AnimationRevealPage";
import CourseObjectivePage from "./CourseObjectivePage";
import FooterPage from "components/Footer";
import GermanLanguagePage from "pages/home/GermanLanguage";
import LangIMG from "images/co-work.svg";
import OverviewSection from "./OverviewSection";
import SEO from "components/seo/SEO";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw.section` mt-32  relative`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
const ContactUsWrapper = tw.section`text-center md:text-center max-w-screen-xl mx-auto mt-12 mb-10 md:mt-40 md:mb-20`;

function IndividualCoursePage() {
  return (
    <AnimationRevealPage>
       <SEO
        title="Individual Courses - Weg2De Akademie"
        description="Individual Courses | Learn German Online | German Individual Courses | German for Professionals "
        name="Weg2De - Akademie"
        type=""
      />
      <Container>
        <OverviewSection heading="Individual " highlight=' Courses' />
        <CourseObjectivePage />
        <ContactUsWrapper>
        </ContactUsWrapper>
        <GermanLanguagePage
          subheading="Individual Course"
          heading="Do you want to book an Individual Course?"
          imageSrc={LangIMG}
          showDecoratorBlob={true}
          textOnLeft={false}
          primaryButtonText="Contact us"
        /> 
        <FooterPage />
        <DecoratorBlob1 />
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
}

export default IndividualCoursePage
