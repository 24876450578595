import AnimationRevealPage from "helpers/AnimationRevealPage";
import FooterPage from "components/Footer";
import GermanLanguagePage from "pages/home/GermanLanguage";
import LangIMG from "images/job-search.svg";
import OverviewSection from "./OverviewSection";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import styled from "styled-components";
import tw from "twin.macro";
import SEO from "components/seo/SEO";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw.section` mt-32  relative`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

//meta title
document.title="Job Search Workshop - Work in Germany - Weg2De Akademie";

function JobSearchPage() {
  return (
    <AnimationRevealPage>
      <SEO
        title="Job Search Workshop - Work in Germany - Weg2De Akademie"
        description="Job Search Workshop - Work in Germany - How to find a job in Germany - Weg2De Akademie"
        name="Job Search Workshop "
        type=""
      />
      <Container>
        <OverviewSection heading="Job " highlight=' Search Workshop' />
        <GermanLanguagePage
          subheading="FIND A JOB"
          heading="Ready to get started?"
          description="Join our Job Search Workshop and take the next step toward a successful career in Germany. 
          Sign up today to unlock your 
          potential and stand out in the German job market! Contact Information: 
          For more details and to book your spot, contact us."
          primaryButtonText="Book us"
          imageSrc={LangIMG}
          showDecoratorBlob={true}
          textOnLeft={false}
          primaryButtonUrl="/contact-us"
        />
        
     
        <FooterPage />
        <DecoratorBlob1 />
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
}

export default JobSearchPage
