import React, { useState } from "react";
//import { SectionHeading, Subheading } from "components/misc/Headings.js";

import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

const Content = tw.section`max-w-screen-xl mx-auto`;


const FAQContent = tw.div`lg:ml-12`;

const FAQSContainer = tw.dl``;
const FAQ = tw.div`cursor-pointer border md:border-none  mt-8  border-b border-secondary-200 px-8 py-4 lg:p-0`;
const Question = tw.dt`flex justify-between items-center text-primary-500`;
const QuestionText = tw.span`font-serif md:text-left text-sm md:text-base lg:text-xl font-normal leading-loose text-gray-500`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2  bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-500 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(
  tw.dd`md:pl-10 tracking-wide text-sm md:text-lg leading-relaxed font-normal text-gray-500`
);

function InnerFaqPage({ faqs }) {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <>
      <Content>
        <FAQContent>
          <FAQSContainer>
            {faqs?.map((faq, index) => (
              <FAQ
                key={faq.id}
                onClick={() => {
                  toggleQuestion(faq.id);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon>
                    {activeQuestionIndex === faq.id ? (
                      <MinusIcon />
                    ) : (
                      <PlusIcon />
                    )}
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === faq.id ? "open" : "collapsed"}
                  transition={{
                    duration: 0.3,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </FAQContent>
      </Content>
    </>
  );
}

export default InnerFaqPage;
