import React, { useRef } from "react";

import tw from "twin.macro";
import { useCountUp } from "react-countup";

const Counter = tw.section`flex-col flex items-center py-5 w-3/4 md:w-40 px-5 shadow-special mb-5`;
const Text = tw.p`font-light font-serif text-gray-300 uppercase tracking-widest`;
const Count = tw.span`font-normal font-serif text-gray-500 text-3xl sm:text-4xl lg:text-5xl`;

const CountUp = ({ title, startCount, endCount, countDuration }) => {
  const countUpRef = useRef(null);
  useCountUp({
    ref: countUpRef,
    start: startCount,
    end: endCount,
    duration: countDuration,
    enableScrollSpy: true,
  });
  return (
    <Counter>
      <Count ref={countUpRef} />
      <Text>{title}</Text>
    </Counter>
  );
};

export default CountUp;
