import { HighlightedText, MainHeading } from "components/misc/Headings";

import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";
import SEO from "components/seo/SEO";

const LeftColumn = tw.section`md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1`;
const Pill = tw.span`font-serif md:tracking-widest px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section` mt-5 flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`mt-5 ml-3 md:ml-0 list-disc md:list-none text-primary-500`;
const Container = tw.section`md:mt-10 max-w-screen-xl mx-auto`;

const OverviewSection = ({ heading, highlight }) => {
  return (
    <Container>
      <SEO
        title="Work and live in Germany | Integration Course - Weg2De"
        description="Work and Live in Germany, are you new in Germany and need help navigating? Weg2De can help"
        name="Work and live in Germany "
        type=""
      />
      <MainHeading>
        {heading}
        <HighlightedText>{highlight} </HighlightedText>
      </MainHeading>
      <TwoColumn>
        <LeftColumn>
          <Pill>PROGRAM DETAILS</Pill>

          <SectionDescription>
            Are you planning a move to Germany, or have you recently arrived and
            need some guidance? Adjusting to life in a new country can be both
            exciting and challenging, especially when navigating unfamiliar job
            markets, cultural nuances, and daily life essentials. Our Working
            and Living in Germany Coaching Service is designed to be your
            comprehensive guide, helping you settle in, integrate, and build a
            successful career and personal life in Germany.
          </SectionDescription>
          <SectionDescription>
            Our tailored coaching sessions cover all aspects of your new life in
            Germany, from job market insights to cultural integration. Whether
            you’re looking for your dream job, need support with bureaucratic
            processes, or simply want to understand the local customs, our
            expert coaches provide the tools, knowledge, and confidence you
            need. We aim to make your transition smoother by offering practical
            advice and personalized support every step of the way.
          </SectionDescription>
          <SectionDescription></SectionDescription>
        </LeftColumn>
        <RightColumn>
          <Pill>What to Expect When Working in Germany:</Pill>

          <ListWrapper>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Work Culture</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                German workplaces are known for their efficiency, punctuality,
                and emphasis on quality. Understanding local business etiquette
                and workplace norms, such as communication styles, hierarchy,
                and teamwork, is key to integrating smoothly.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Legal Recuirements</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Navigating the legal requirements for working in Germany,
                including obtaining the right visa or work permit, is crucial.
                Additionally, knowledge of local employment laws, contracts, and
                labor rights will help you settle confidently into your new job.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Professional Development</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                German employers often value qualifications, so having your
                diplomas and certifications recognized can be beneficial.
                Continuous learning and professional development are highly
                encouraged in the German job market.
              </SectionDescription>
            </ListItem>
          </ListWrapper>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default OverviewSection;
