import CustomerCount from "./CountUp";
import React from "react";
import tw from "twin.macro";

const CounterWrapper = tw.section`mt-20 font-normal flex flex-col md:flex-row items-center justify-around`;

const CustomerCountPage = ({ data }) => {
  return (
    <CounterWrapper>
      {data?.map((count) => (
        <CustomerCount key={count?.id} {...count} />
      ))}
    </CounterWrapper>
  );
};

export default CustomerCountPage;
