import { SectionHeading, Subheading } from "components/misc/Headings";

import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1`;
//const Pill = tw.span`font-serif md:tracking-widest px-2 md:px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const TwoColumn = tw.section`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto `;
const SubTitle = tw(Subheading)` tracking-widest`;
const Center = tw(SectionHeading)`text-center`;
const Container = tw.section`md:mt-10 max-w-screen-xl mx-auto`;

const CourseObjectivePage = () => {
  return (
    <Container>
      <Center>Course Objectives</Center>
      <TwoColumn>
        <LeftColumn>
          <SubTitle>
            <Subheading>A1 Level</Subheading>
          </SubTitle>
          <SectionDescription>
            Acquire basic vocabulary and simple grammar to communicate in
            everyday situations. Learn to introduce yourself, ask and answer
            basic questions, and comprehend simple texts.
          </SectionDescription>
          <SubTitle>
            <Subheading>A2 Level</Subheading>
          </SubTitle>
          <SectionDescription>
            Expand your ability to discuss familiar topics, manage routine
            tasks, and understand simple dialogues and texts.
          </SectionDescription>
        </LeftColumn>
        <RightColumn>
          <SubTitle>
            <Subheading>B1 Level</Subheading>
          </SubTitle>
          <SectionDescription>
            Develop confidence in complex conversations, express opinions, and
            understand and produce detailed written and spoken German.
          </SectionDescription>
          <SubTitle>
            <Subheading> B2 Level</Subheading>
          </SubTitle>
          <SectionDescription>
            Achieve fluency and accuracy in a range of topics, with an emphasis
            on refining your understanding of complex texts and expressing ideas
            clearly.
          </SectionDescription>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default CourseObjectivePage;
