import { hiringStepsData, servicesCardData } from "data/dummyData";
import AboutCompany from "./AboutCompany";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import CompanyLanding from "./Landing";
import CompanyServicesPage from "./CompanyServices";
import FooterPage from "components/Footer";
import HiringSteps from "./HiringSteps";
import React from "react";
import SEO from "components/seo/SEO";
import SourcingForm from "./AppointmentBooking";

function CompaniesPage() {
  return (
    <AnimationRevealPage>
      <SEO
        title="Arbeitgeber - Fachkräfte und Azubis aus aller Welt finden - Weg2De"
        description="Weg2De - Ausländische Fachkräfte einstellen | Recruit Foreign Workers to Germany | Hörakustiker finden | Fachkräfte und Azubis aus aller Welt finden"
        name="Weg2De - Arbeitgeber"
        type=""
      />
      <CompanyLanding />
      <AboutCompany subheading="Über Uns" />
      <CompanyServicesPage heading="Wir vermitteln" data={servicesCardData} />
      <HiringSteps
        heading="In 5 Schritten zu geeignete Mitarbeiter"
        data={hiringStepsData}
      />
      <SourcingForm subheading="Jetzt Talent Anfordern" />
      <FooterPage />
    </AnimationRevealPage>
  );
}

export default CompaniesPage;
