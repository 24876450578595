import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import { SectionHeading } from "components/misc/Headings";
import tw from "twin.macro";

const LeftColumn = tw.section`md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1`;
const TwoColumn = tw.section`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center `;
const IconWrapper = tw.div` flex items-center text-primary-500`;
const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`list-disc md:list-none text-primary-500`;

const KeyTopicsPage = () => {
  return (
    <TwoColumn>
      <LeftColumn>
        <SectionHeading>Key topics covered include:</SectionHeading>
        <ListWrapper>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
                Vocabulary related to IT, engineering and healthcare professions
              </TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>Describing job responsibilities and tasks</TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
                Discussing technological advancements and medical
              </TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>Handling emergencies and crisis situations</TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>Structuring and delivering presentations</TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>Writing reports and summaries</TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>Participating in meetings and discussions</TextItem>
            </IconWrapper>
          </ListItem>
        </ListWrapper>
      </LeftColumn>
      <RightColumn>
        {/* <MarginTop /> */}
        <SectionDescription>
          Instructors utilize a variety of teaching methodologies, including
          interactive exercises, role-plays, multimedia resources, and real-life
          simulations, to create an immersive and dynamic learning environment.
          Learners receive regular feedback on their language performance and
          progress, enabling them to track their development and address areas
          for improvement effectively.
        </SectionDescription>
        <SectionDescription>
          By the end of the course, participants will have gained the linguistic
          skills and confidence to engage confidently in German-language
          interactions within their professional contexts, enhancing their
          career opportunities and cross-cultural communication abilities in the
          global marketplace.
        </SectionDescription>
      </RightColumn>
    </TwoColumn>
  );
};

export default KeyTopicsPage;
